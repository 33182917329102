import React, { useState, useEffect } from 'react';
import OpenAI from "openai";
import './InsightsModalYearly.css';

const InsightsModalYearly = ({ isOpen, onClose, yearEntries, selectedYear, llmToken, onInsightGenerated }) => {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen) {
      const savedPrompt = localStorage.getItem(`prompt_yearly_${selectedYear}`);
      const savedResponse = localStorage.getItem(`response_yearly_${selectedYear}`);
      if (savedPrompt) setPrompt(savedPrompt);
      if (savedResponse) setResponse(savedResponse);
    }
  }, [isOpen, selectedYear]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setError('');

    const openai = new OpenAI({ 
      apiKey: llmToken, 
      dangerouslyAllowBrowser: true
    });

    const formattedEntries = Object.entries(yearEntries)
      .map(([date, entry]) => `${date}: ${entry}`)
      .join('\n\n');

    const fullPrompt = `
Yearly Journal Entries:

${formattedEntries}

User Prompt: ${prompt}

Please analyze these journal entries for the entire year and respond to the user's prompt. Additionally, provide a sentiment score for the year on a scale of 1 to 100, where 1 is extremely negative, 50 is neutral, and 100 is extremely positive. Format your response as follows:

Analysis: [Your analysis here]

Sentiment Score: [Number between 1 and 100]

Sentiment Summary: [Brief explanation of the sentiment score]

Key Themes: [List the main themes or patterns observed throughout the year]

Personal Growth: [Discuss areas of personal growth or challenges overcome]

Goals for Next Year: [Suggest potential goals or areas for improvement based on the year's entries]
`;

    try {
      const completion = await openai.chat.completions.create({
        messages: [
          { role: "system", content: "You are a helpful assistant analyzing yearly journal entries and providing insights." },
          { role: "user", content: fullPrompt }
        ],
        model: "gpt-4",
      });

      const newResponse = completion.choices[0].message.content;
      setResponse(newResponse);
      
      const sentimentMatch = newResponse.match(/Sentiment Score: (\d+)/);
      const sentimentScore = sentimentMatch ? parseInt(sentimentMatch[1]) : 50;

      localStorage.setItem(`prompt_yearly_${selectedYear}`, prompt);
      localStorage.setItem(`response_yearly_${selectedYear}`, newResponse);

      onInsightGenerated(selectedYear, newResponse, sentimentScore, prompt);
    } catch (error) {
      setError('An error occurred while fetching the response. Please try again.');
      console.error('OpenAI API Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatResponse = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  if (!isOpen) return null;

  return (
    <div className="insights-modal-yearly">
      <h2>Yearly Insights</h2>
      <h3>{selectedYear}</h3>
      <textarea
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Default Prompt: Analyze my year, highlighting key events, personal growth, and areas for improvement. Suggest goals for the coming year based on my experiences."
      />
      <div className="button-container">
        <button onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? 'Analyzing...' : 'Get Yearly Insights'}
        </button>
        <button onClick={onClose}>Close</button>
      </div>
      {error && <p className="error">{error}</p>}
      {response && (
        <div className="response">
          <h3>Analysis:</h3>
          <div className="response-text">{formatResponse(response)}</div>
        </div>
      )}
    </div>
  );
};

export default InsightsModalYearly;