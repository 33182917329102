import React, { useState, useEffect } from 'react';
import OpenAI from "openai";
import './InsightsModal.css';

const InsightsModal = ({ isOpen, onClose, weekEntries, selectedWeek, llmToken, onInsightGenerated }) => {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen) {
      // Load saved prompt and response if they exist
      const savedPrompt = localStorage.getItem(`prompt_${selectedWeek}`);
      const savedResponse = localStorage.getItem(`response_${selectedWeek}`);
      if (savedPrompt) setPrompt(savedPrompt);
      if (savedResponse) setResponse(savedResponse);
    }
  }, [isOpen, selectedWeek]);

  useEffect(() => {
    console.log("Week entries received");
  }, [weekEntries]);

  const getWeekNumber = (weekKey) => {
    const [year, week] = weekKey.split('-W');
    return parseInt(week, 10);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError('');

    const openai = new OpenAI({ 
      apiKey: llmToken, 
      dangerouslyAllowBrowser: true // Only use this for local development
    });

    const formattedEntries = Object.entries(weekEntries)
      .map(([date, entry]) => `${date}: ${entry}`)
      .join('\n\n');

    console.log("Formatted entries");

    if (formattedEntries.trim() === '') {
      setError('No entries found for this week. Please write some journal entries before generating insights.');
      setIsLoading(false);
      return;
    }

    const fullPrompt = `
Weekly Journal Entries:

${formattedEntries}

User Prompt: ${prompt || "Summarize my week and identify any negative cognitive distortions. Discuss how I can improve my mental health."}

Please analyze these journal entries and respond to the user's prompt. Additionally, provide a sentiment score for the week on a scale of 1 to 100, where 1 is extremely negative, 50 is neutral, and 100 is extremely positive. Format your response as follows:

Analysis: [Your analysis here]

Sentiment Score: [Number between 1 and 100]

Sentiment Summary: [Brief explanation of the sentiment score]
`;

    console.log("Full prompt sent to LLM");

    try {
      const completion = await openai.chat.completions.create({
        messages: [
          { role: "system", content: "You are a helpful assistant analyzing journal entries and providing insights." },
          { role: "user", content: fullPrompt }
        ],
        model: "gpt-4",
      });

      const newResponse = completion.choices[0].message.content;
      console.log("LLM Response");
      setResponse(newResponse);
      
      // Extract sentiment score
      const sentimentMatch = newResponse.match(/Sentiment Score: (\d+)/);
      const sentimentScore = sentimentMatch ? parseInt(sentimentMatch[1]) : 50; // Default to neutral if not found

      // Save prompt and response
      localStorage.setItem(`prompt_${selectedWeek}`, prompt);
      localStorage.setItem(`response_${selectedWeek}`, newResponse);

      // Notify parent component that an insight was generated
      onInsightGenerated(selectedWeek, newResponse, sentimentScore, prompt);
    } catch (error) {
      setError('An error occurred while fetching the response. Please try again.');
      console.error('OpenAI API Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatResponse = (text) => {
    // Replace newlines with <br> tags
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  if (!isOpen) return null;

  const weekNumber = getWeekNumber(selectedWeek);

  return (
    <div className="insights-modal">
      <h2>Weekly Insights</h2>
      <p>Week {weekNumber}</p>
      <textarea
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Default Prompt: Summarize my week and identify any negative cognitive distortions. Discuss how I can improve my mental health."
      />
      <div className="button-container">
        <button onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? 'Analyzing...' : 'Get Insights'}
        </button>
        <button onClick={onClose}>Close</button>
      </div>
      {error && <p className="error">{error}</p>}
      {response && (
        <div className="response">
          <h3>Analysis:</h3>
          <div className="response-text">{formatResponse(response)}</div>
        </div>
      )}
    </div>
  );
};

export default InsightsModal;