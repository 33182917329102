import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import './JournalEntryModal.css';

const JournalEntryModal = ({ date, content, onSave, onClose }) => {
  const defaultMessage = `Welcome to your journal entry! Here are some best practices to help you get the most out of your journaling:

- Use personal language: Write as if you're talking to yourself or a close friend.
- Discuss your feelings: Pay attention to both emotional and physical sensations.
- Explore your thoughts: Write about ideas or concerns that keep coming back to you.
- Aim for 300-500 words: If you're struggling to write, try using bullet points.

Remember, there's no right or wrong way to journal. The goal is to express yourself freely and reflect on your experiences.

---

Replace me to start your entry!`;

  const [entryContent, setEntryContent] = useState(content || defaultMessage);
  const [wordCount, setWordCount] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [buttonText, setButtonText] = useState('Save');

  useEffect(() => {
    updateWordCount(entryContent);
  }, [entryContent]);

  const handleSave = async () => {
    setIsSaving(true);
    setButtonText('Saving...');

    try {
      await onSave(date, entryContent);

      setButtonText('Saved!');
      setTimeout(() => {
        setSaveStatus('');
        setButtonText('Save');
        setIsSaving(false);
      }, 2000);
    } catch (error) {
      console.error("Error saving entry:", error);

      setButtonText('Error');
      setTimeout(() => {

        setButtonText('Save');
        setIsSaving(false);
      }, 2000);
    }
  };

  const updateWordCount = (text) => {
    const words = text.trim().split(/\s+/);
    setWordCount(text.trim() === '' ? 0 : words.length);
  };

  const handleContentChange = (e) => {
    setEntryContent(e.target.value);
    setSaveStatus('');
    if (buttonText !== 'Save') {
      setButtonText('Save');
    }
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const entryDate = new Date(year, month - 1, day);
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    return `${monthNames[entryDate.getMonth()]} ${entryDate.getDate()}, ${year}`;
  };

  const renderMarkdown = (text) => {
    const rawMarkup = marked(text);
    const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: sanitizedMarkup };
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  return (
    <div className={`modal ${showPreview ? 'show-preview' : ''}`}>
      <h2>{formatDate(date)}</h2>
      <div className="entry-container">
        <div className="editor-preview-container">
          <textarea
            value={entryContent}
            onChange={handleContentChange}
            placeholder="Write your journal entry here... (Markdown supported)"
          />
          {showPreview && (
            <div 
              className="preview-container"
              dangerouslySetInnerHTML={renderMarkdown(entryContent)}
            />
          )}
        </div>
      </div>
      <div className="modal-footer">
        <div className="word-count">Words: {wordCount}</div>
        <div className={`save-status ${saveStatus ? 'active' : ''}`}>{saveStatus}</div>
        <div className="button-container">
          <button onClick={togglePreview}>
            {showPreview ? 'Hide Preview' : 'Markdown Preview'}
          </button>
          <button 
            onClick={handleSave} 
            disabled={isSaving}
            className={buttonText === 'Saved!' ? 'saved' : ''}
          >
            {buttonText}
          </button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default JournalEntryModal;