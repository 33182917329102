import React, { useState, useEffect } from 'react';
import OpenAI from "openai";
import './InsightsModalMonthly.css';

const InsightsModalMonthly = ({ isOpen, onClose, monthEntries, selectedMonth, llmToken, onInsightGenerated }) => {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen) {
      const savedPrompt = localStorage.getItem(`prompt_monthly_${selectedMonth}`);
      const savedResponse = localStorage.getItem(`response_monthly_${selectedMonth}`);
      if (savedPrompt) setPrompt(savedPrompt);
      if (savedResponse) setResponse(savedResponse);
    }
  }, [isOpen, selectedMonth]);

  const getMonthName = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'long' });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError('');

    const openai = new OpenAI({ 
      apiKey: llmToken, 
      dangerouslyAllowBrowser: true
    });

    const formattedEntries = Object.entries(monthEntries)
      .map(([date, entry]) => `${date}: ${entry}`)
      .join('\n\n');

    const fullPrompt = `
Monthly Journal Entries:

${formattedEntries}

User Prompt: ${prompt}

Please analyze these journal entries for the month and respond to the user's prompt. Additionally, provide a sentiment score for the month on a scale of 1 to 100, where 1 is extremely negative, 50 is neutral, and 100 is extremely positive. Format your response as follows:

Analysis: [Your analysis here]

Sentiment Score: [Number between 1 and 100]

Sentiment Summary: [Brief explanation of the sentiment score]
`;

    try {
      const completion = await openai.chat.completions.create({
        messages: [
          { role: "system", content: "You are a helpful assistant analyzing monthly journal entries and providing insights." },
          { role: "user", content: fullPrompt }
        ],
        model: "gpt-4",
      });

      const newResponse = completion.choices[0].message.content;
      setResponse(newResponse);
      
      const sentimentMatch = newResponse.match(/Sentiment Score: (\d+)/);
      const sentimentScore = sentimentMatch ? parseInt(sentimentMatch[1]) : 50;

      localStorage.setItem(`prompt_monthly_${selectedMonth}`, prompt);
      localStorage.setItem(`response_monthly_${selectedMonth}`, newResponse);

      onInsightGenerated(selectedMonth, newResponse, sentimentScore, prompt);
    } catch (error) {
      setError('An error occurred while fetching the response. Please try again.');
      console.error('OpenAI API Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatResponse = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  if (!isOpen) return null;

  return (
    <div className="insights-modal-monthly">
      <h2>Monthly Insights</h2>
      <h3>{getMonthName(selectedMonth)}</h3>
      <textarea
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Default Prompt: Summarize my month and identify any recurring themes or patterns. Discuss how I can build on positive trends and address challenges."
      />
      <div className="button-container">
        <button onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? 'Analyzing...' : 'Get Insights'}
        </button>
        <button onClick={onClose}>Close</button>
      </div>
      {error && <p className="error">{error}</p>}
      {response && (
        <div className="response">
          <h3>Analysis:</h3>
          <div className="response-text">{formatResponse(response)}</div>
        </div>
      )}
    </div>
  );
};

export default InsightsModalMonthly;