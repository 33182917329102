import React from 'react';
import { auth } from './firebaseConfig';
import { signOut } from 'firebase/auth';
import './SettingsSidebar.css';

const SettingsSidebar = ({ isOpen, onClose }) => {
  const handleLogout = async () => {
    if (window.confirm("Are you sure you want to log out?")) {
      try {
        await signOut(auth);
        // The onAuthStateChanged listener in App.js will handle the state update
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="settings-sidebar">
      <div className="settings-content">
        <div className="settings-header">
          <h2>Help and Settings</h2>
        </div>
        <div className="settings-section">
          <h3>About</h3>
          <p>The Quotidian journal is a minimalistic journal app designed to help you reflect on your daily experiences and gain insights into your thoughts and emotions over time.</p>
        </div>
        <div className="settings-section">
          <h3>How To Use</h3>
          <h4>Journal Entries</h4>
          <p>Click on any day to create or edit an entry. The color of each box changes based on the length of your entry:</p>
          <ul>
            <li>Light green: Short entries</li>
            <li>Dark green: Longer entries (darkest at 500 words)</li>
            <li>Try to be thorough, but sometimes we only have the brain power for bullet points</li>
          </ul>
          <h4>Markdown Viewer</h4>
          <p>As you type, you'll see a live preview of your formatted text. This helps if you plan to export your entries in Markdown format.</p>
          <h4>Insights</h4>
          <p>The boxes below your entries, in lighter grey, represent AI-generated insights:</p>
          <ul>
            <li>Weekly insights: Bottom row</li>
            <li>Monthly insights: Second from bottom</li>
            <li>Yearly insight: Bottom right corner</li>
          </ul>
          <p>These boxes change color based on the overall sentiment of your entries:</p>
          <ul>
            <li>Red: More negative sentiment</li>
            <li>Blue: More positive sentiment</li>
          </ul>
          <p>Remember, the goal is to be authentic in your journaling. The sentiment colors are there to help you visualize your emotional journey, not to influence how you write.</p>
        </div>
        <div className="settings-section">
          <h3>Features</h3>
          <ul>
            <li>Daily journaling with a clean, distraction-free interface</li>
            <li>Weekly, monthly, and yearly AI-powered insights</li>
            <li>Visual representation of your journaling habits</li>
            <li>More features to come!</li>
          </ul>
        </div>
        <div className="settings-section">
          <h3>Version</h3>
          <p>Quotidian Journal v1.0.0</p>
        </div>
        <div className="settings-section">
          <h3>TODO</h3>
          <ul>
            <li>Connect to Firebase for cloud hosting and auth</li>
            <li>Create export functionality to allow for local saving</li>
            <li>Dark mode</li>
          </ul>
        </div>
      </div>
      <div className="logout-container">
        <button onClick={handleLogout} className="logout-button">Log Out</button>
      </div>
    </div>
  );
};

export default SettingsSidebar;