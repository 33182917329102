// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCY88o87d-WlYW8oYHcBIcwHE-7o4OO19s",
  authDomain: "quotidian-journal.firebaseapp.com",
  projectId: "quotidian-journal",
  storageBucket: "quotidian-journal.appspot.com",
  messagingSenderId: "701686601415",
  appId: "1:701686601415:web:f548f4a6b91a3dfad6d0e2",
  measurementId: "G-JB1R2PMZ2M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);