import React from 'react';
import Box from './Box';
import './Calendar.css';

const Calendar = ({ entries, onBoxClick, entryCount, year, onInsightClick, insights, onMonthlyInsightClick, monthlyInsights = {}, onYearlyInsightClick, yearlyInsights }) => {
  const daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  
  const getFirstDayOfYear = (year) => {
    return new Date(year, 0, 1).getDay();
  };
  
  const getDaysInYear = (year) => {
    return ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) ? 366 : 365;
  };

  const getISOWeek = (date) => {
    const d = new Date(date);
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return `${d.getUTCFullYear()}-W${weekNo.toString().padStart(2, '0')}`;
  };

  const firstDayOfYear = getFirstDayOfYear(year);
  const totalDays = getDaysInYear(year);
  const offset = firstDayOfYear === 0 ? 6 : firstDayOfYear - 1;

  // Get today's date
  const today = new Date();
  const todayString = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

  const gridDates = Array.from({ length: 53 * 7 }, (_, index) => {
    const dayOfYear = index - offset + 1;
    if (dayOfYear < 1 || dayOfYear > totalDays) {
      return null;
    }
    const date = new Date(year, 0, dayOfYear);
    return `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  });

  const getWeekStart = (date) => {
    if (!date) return null;
    const [yearStr, monthStr, dayStr] = date.split('-');
    const dateObj = new Date(parseInt(yearStr), parseInt(monthStr) - 1, parseInt(dayStr));
    const day = dateObj.getDay();
    const diff = dateObj.getDate() - day + (day === 0 ? -6 : 1);
    const weekStart = new Date(dateObj.setDate(diff));
    return `${weekStart.getFullYear()}-${(weekStart.getMonth() + 1).toString().padStart(2, '0')}-${weekStart.getDate().toString().padStart(2, '0')}`;
  };

  const getMonthEndDates = () => {
    const monthEnds = [];
    for (let month = 0; month < 12; month++) {
      const lastDay = new Date(year, month + 1, 0);
      monthEnds.push(`${year}-${(month + 1).toString().padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')}`);
    }
    return monthEnds;
  };

  const monthEndDates = getMonthEndDates();

  // Create a mapping of column indices to month-end dates
  const monthEndColumns = monthEndDates.reduce((acc, date) => {
    const index = gridDates.findIndex(d => d === date);
    if (index !== -1) {
      acc[Math.floor(index / 7)] = date;
    }
    return acc;
  }, {});

  // Find the column index for December 31st
  const yearEndDate = `${year}-12-31`;
  const yearEndColumnIndex = Math.floor(gridDates.findIndex(d => d === yearEndDate) / 7);

  const disclaimer = `IMPORTANT: AI insights are NOT medical advice. ALWAYS consult a healthcare professional for mental health concerns.`;

  return (
    <div className="calendar-container">
      <div className="header">
        <h1>quotidian</h1>
        <p>{entryCount} journal entries</p>
        <p>{year} - {totalDays} days</p>
      </div>
      <div className="calendar-grid">
        {/* Daily entry grid */}
        {daysOfWeek.map((day, rowIndex) => (
          <div key={rowIndex} className="row">
            <div className="day-label">{day}</div>
            {Array.from({ length: 53 }).map((_, colIndex) => {
              const index = rowIndex + colIndex * 7;
              const date = gridDates[index];
              const weekStart = getWeekStart(date);
              return (
                <Box
                  key={index}
                  date={date}
                  content={date ? entries[date] : null}
                  onClick={date ? onBoxClick : null}
                  disabled={!date}
                  isToday={date === todayString}
                  isInsight={false}
                  insight={insights[weekStart]}
                />
              );
            })}
          </div>
        ))}

        {/* Weekly Insights row */}
        <div className="row insights-row">
          <div className="day-label">week</div>
          {Array.from({ length: 53 }).map((_, colIndex) => {
            const weekStart = getWeekStart(gridDates[colIndex * 7]);
            const weekKey = weekStart ? getISOWeek(new Date(weekStart)) : null;
            return (
              <Box
                key={`insight-${colIndex}`}
                date={weekStart}
                content={null}
                onClick={() => weekStart && onInsightClick(weekStart)}
                isInsight={true}
                insight={weekKey ? insights[weekKey] : null}
              />
            );
          })}
        </div>

        {/* Monthly Insights row */}
        <div className="row insights-row">
          <div className="day-label">month</div>
          {Array.from({ length: 53 }).map((_, colIndex) => {
            const monthEndDate = monthEndColumns[colIndex];
            return (
              <Box
                key={`monthly-insight-${colIndex}`}
                date={monthEndDate}
                content={null}
                onClick={() => monthEndDate && onMonthlyInsightClick && onMonthlyInsightClick(monthEndDate)}
                isInsight={true}
                insight={monthEndDate ? monthlyInsights[monthEndDate] : null}
                disabled={!monthEndDate}
              />
            );
          })}
        </div>

        {/* Yearly Insight row */}
        <div className="row insights-row">
        <div className="day-label">year</div>
        {Array.from({ length: 53 }).map((_, colIndex) => {
          const isYearEnd = colIndex === yearEndColumnIndex;
          return (
            <Box
              key={`yearly-insight-${colIndex}`}
              date={isYearEnd ? yearEndDate : null}
              content={null}
              onClick={() => isYearEnd && onYearlyInsightClick && onYearlyInsightClick(year.toString())}
              isInsight={true}
              insight={isYearEnd ? yearlyInsights[year.toString()] : null}
              disabled={!isYearEnd}
            />
          );
        })}
      </div>
      
      </div>
      <div className="disclaimer-wrapper">
        <div className="disclaimer">
          <p>{disclaimer}</p>
        </div>
      </div>
    </div>
  );
};

export default Calendar;