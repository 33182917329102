import React, { useMemo } from 'react';
import './Box.css';

const Box = ({ date, content, onClick, disabled, isToday, isInsight, insight }) => {
    const getSentimentColor = (sentiment) => {
        if (sentiment === undefined) return '#f0f0f0'; // Light grey for no insight
      
        let startColor, endColor, factor;
      
        if (sentiment <= 49) {
          // Negative sentiment: Pure red to very light red
          startColor = { r: 255, g: 0, b: 0 }; // #FF0000
          endColor = { r: 255, g: 204, b: 204 }; // Very light red
          factor = (sentiment - 1) / 48;
        } else {
          // Positive sentiment: Very light blue to pure blue
          startColor = { r: 204, g: 229, b: 255 }; // Very light blue
          endColor = { r: 0, g: 0, b: 255 }; // #0000FF
          factor = (sentiment - 50) / 50;
        }
      
        // Interpolate between the start and end colors
        const r = Math.round(startColor.r * (1 - factor) + endColor.r * factor);
        const g = Math.round(startColor.g * (1 - factor) + endColor.g * factor);
        const b = Math.round(startColor.b * (1 - factor) + endColor.b * factor);
      
        // Convert RGB to hex
        const toHex = (value) => {
          const hex = value.toString(16);
          return hex.length === 1 ? `0${hex}` : hex;
        };
      
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };
      
    const getColor = useMemo(() => {
        if (disabled) return '#ffffff';
        if (isInsight) return getSentimentColor(insight?.sentiment);
        if (!content) return '#e0e0e0';
        const wordCount = content.split(' ').length;
        const maxWords = 500;
        const lightness = 90 - (Math.min(wordCount, maxWords) / maxWords) * 60;
        return `hsl(120, 60%, ${lightness}%)`;
    }, [disabled, isInsight, insight, content]);

    return (
        <div
            className={`box ${disabled ? 'disabled' : ''} ${isToday ? 'today' : ''} ${isInsight ? 'insight' : ''}`}
            style={{ backgroundColor: getColor }}
            onClick={disabled ? null : () => onClick(date)}
        />
    );
};

export default React.memo(Box);