import React, { useState, useEffect, useCallback } from 'react';
import Calendar from './Calendar';
import JournalEntryModal from './JournalEntryModal';
import InsightsModal from './InsightsModal';
import InsightsModalMonthly from './InsightsModalMonthly';
import InsightsModalYearly from './InsightsModalYearly';
import SettingsSidebar from './SettingsSidebar';
import AuthModal from './AuthModal';
import { auth, db } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, doc, setDoc, getDoc, getDocs } from 'firebase/firestore';
import './App.css';

const App = () => {
  const [entries, setEntries] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [showInsightsModal, setShowInsightsModal] = useState(false);
  const [showMonthlyInsightsModal, setShowMonthlyInsightsModal] = useState(false);
  const [showYearlyInsightsModal, setShowYearlyInsightsModal] = useState(false);
  const [entryCount, setEntryCount] = useState(0);
  const [insights, setInsights] = useState({});
  const [monthlyInsights, setMonthlyInsights] = useState({});
  const [yearlyInsights, setYearlyInsights] = useState({});
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [showSettingsSidebar, setShowSettingsSidebar] = useState(false);
  const [user, setUser] = useState(null);
  const year = new Date().getFullYear();

  const llmToken = process.env.REACT_APP_LLM_API_KEY;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchData(currentUser.uid);
      } else {
        setEntries({});
        setInsights({});
        setMonthlyInsights({});
        setYearlyInsights({});
        setEntryCount(0);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchData = async (userId) => {
    try {
      const entriesRef = collection(db, 'users', userId, 'entries');
      const entriesSnapshot = await getDocs(entriesRef);
      const entriesData = {};
      entriesSnapshot.forEach((doc) => {
        entriesData[doc.id] = doc.data().content;
      });
      setEntries(entriesData);

      const weeklyInsightsRef = collection(db, 'users', userId, 'weeklyInsights');
      const weeklyInsightsSnapshot = await getDocs(weeklyInsightsRef);
      const weeklyInsightsData = {};
      weeklyInsightsSnapshot.forEach((doc) => {
        weeklyInsightsData[doc.id] = doc.data();
      });
      setInsights(weeklyInsightsData);

      const monthlyInsightsRef = collection(db, 'users', userId, 'monthlyInsights');
      const monthlyInsightsSnapshot = await getDocs(monthlyInsightsRef);
      const monthlyInsightsData = {};
      monthlyInsightsSnapshot.forEach((doc) => {
        monthlyInsightsData[doc.id] = doc.data();
      });
      setMonthlyInsights(monthlyInsightsData);

      const yearlyInsightsRef = collection(db, 'users', userId, 'yearlyInsights');
      const yearlyInsightsSnapshot = await getDocs(yearlyInsightsRef);
      const yearlyInsightsData = {};
      yearlyInsightsSnapshot.forEach((doc) => {
        yearlyInsightsData[doc.id] = doc.data();
      });
      setYearlyInsights(yearlyInsightsData);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (user) {
      const countEntries = () => {
        let count = 0;
        for (const key in entries) {
          if (entries[key]) count++;
        }
        setEntryCount(count);
      };
      countEntries();
    }
  }, [entries, user]);

  const getISOWeek = (date) => {
    const d = new Date(date);
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return `${d.getUTCFullYear()}-W${weekNo.toString().padStart(2, '0')}`;
  };

  const getWeekStart = (weekString) => {
    const [year, week] = weekString.split('-W');
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  };

  const getWeekEntries = (weekStart) => {
    if (!(weekStart instanceof Date) || isNaN(weekStart.getTime())) {
      console.error("Invalid weekStart:", weekStart);
      return {};
    }

    let weekEntries = {};
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(weekStart);
      currentDate.setDate(currentDate.getDate() + i);
      const dateString = currentDate.toISOString().split('T')[0];
      if (entries[dateString]) {
        weekEntries[dateString] = entries[dateString];
      }
    }
    return weekEntries;
  };

  const handleInsightClick = (date) => {
    if (!date) return;
    try {
      const weekKey = getISOWeek(date);
      setSelectedWeek(weekKey);
      setShowInsightsModal(true);
    } catch (error) {
      console.error("Error in handleInsightClick:", error);
    }
  };

  const getMonthEntries = (monthEnd) => {
    const monthStart = new Date(monthEnd);
    monthStart.setDate(1);
    let monthEntries = {};

    while (monthStart <= new Date(monthEnd)) {
      const dateString = monthStart.toISOString().split('T')[0];
      monthEntries[dateString] = entries[dateString] || 'No entry for this day.';
      monthStart.setDate(monthStart.getDate() + 1);
    }

    return monthEntries;
  };

  const getYearEntries = (year) => {
    return Object.entries(entries).reduce((acc, [date, entry]) => {
      if (date.startsWith(year)) {
        acc[date] = entry;
      }
      return acc;
    }, {});
  };

  const handleBoxClick = (date) => {
    setSelectedDate(date);
  };

  const handleSaveEntry = async (date, content) => {
    if (!user) return;

    try {
      const entryRef = doc(db, 'users', user.uid, 'entries', date);
      await setDoc(entryRef, { content }, { merge: true });
      setEntries(prevEntries => ({
        ...prevEntries,
        [date]: content
      }));
      return Promise.resolve();
    } catch (error) {
      console.error("Error saving entry:", error);
      return Promise.reject(error);
    }
  };

  const handleMonthlyInsightClick = (monthEnd) => {
    if (!monthEnd) return;
    setSelectedMonth(monthEnd);
    setShowMonthlyInsightsModal(true);
  };

  const handleYearlyInsightClick = (year) => {
    if (!year) return;
    setSelectedYear(year);
    setShowYearlyInsightsModal(true);
  };

  const toggleSettingsSidebar = () => {
    setShowSettingsSidebar(!showSettingsSidebar);
  };

  const handleSaveInsight = useCallback(async (weekKey, insight, sentimentScore, prompt) => {
    if (!user) return;
  
    try {
      const insightRef = doc(db, 'users', user.uid, 'weeklyInsights', weekKey);
      const insightData = { 
        content: insight, 
        sentiment: sentimentScore,
        prompt: prompt
      };
      await setDoc(insightRef, insightData, { merge: true });
      setInsights(prevInsights => ({
        ...prevInsights,
        [weekKey]: insightData
      }));
    } catch (error) {
      console.error("Error saving insight:", error);
    }
  }, [user]);

  const handleSaveMonthlyInsight = useCallback(async (month, insight, sentimentScore, prompt) => {
    if (!user) return;

    try {
      const insightRef = doc(db, 'users', user.uid, 'monthlyInsights', month);
      const insightData = { 
        content: insight, 
        sentiment: sentimentScore,
        prompt: prompt
      };
      await setDoc(insightRef, insightData, { merge: true });
      setMonthlyInsights(prevInsights => ({
        ...prevInsights,
        [month]: insightData
      }));
    } catch (error) {
      console.error("Error saving monthly insight:", error);
    }
  }, [user]);

  const handleSaveYearlyInsight = useCallback(async (year, insight, sentimentScore, prompt) => {
    if (!user) return;

    try {
      const insightRef = doc(db, 'users', user.uid, 'yearlyInsights', year);
      const insightData = { 
        content: insight, 
        sentiment: sentimentScore,
        prompt: prompt
      };
      await setDoc(insightRef, insightData, { merge: true });
      setYearlyInsights(prevInsights => ({
        ...prevInsights,
        [year]: insightData
      }));
    } catch (error) {
      console.error("Error saving yearly insight:", error);
    }
  }, [user]);

  if (!user) {
    return <AuthModal onLogin={() => {}} />;
  }

  return (
    <div className="app">
      <button 
        className={`settings-toggle ${showSettingsSidebar ? 'active' : ''}`} 
        onClick={toggleSettingsSidebar}
      >
        ?
      </button>
      <Calendar 
        entries={entries} 
        onBoxClick={handleBoxClick} 
        entryCount={entryCount} 
        year={year}
        onInsightClick={handleInsightClick}
        onMonthlyInsightClick={handleMonthlyInsightClick}
        onYearlyInsightClick={handleYearlyInsightClick}
        insights={insights}
        monthlyInsights={monthlyInsights}
        yearlyInsights={yearlyInsights}
      />
      {selectedDate && (
        <JournalEntryModal
          date={selectedDate}
          content={entries[selectedDate]}
          onSave={handleSaveEntry}
          onClose={() => setSelectedDate(null)}
        />
      )}
      {showInsightsModal && (
        <InsightsModal
          isOpen={showInsightsModal}
          onClose={() => setShowInsightsModal(false)}
          weekEntries={getWeekEntries(getWeekStart(selectedWeek))}
          selectedWeek={selectedWeek}
          llmToken={llmToken}
          onInsightGenerated={handleSaveInsight}
        />
      )}
      {showMonthlyInsightsModal && (
        <InsightsModalMonthly
          isOpen={showMonthlyInsightsModal}
          onClose={() => setShowMonthlyInsightsModal(false)}
          monthEntries={getMonthEntries(selectedMonth)}
          selectedMonth={selectedMonth}
          llmToken={llmToken}
          onInsightGenerated={handleSaveMonthlyInsight}
        />
      )}
      {showYearlyInsightsModal && (
        <InsightsModalYearly
          isOpen={showYearlyInsightsModal}
          onClose={() => setShowYearlyInsightsModal(false)}
          yearEntries={getYearEntries(selectedYear)}
          selectedYear={selectedYear}
          llmToken={llmToken}
          onInsightGenerated={handleSaveYearlyInsight}
        />
      )}
      <SettingsSidebar 
        isOpen={showSettingsSidebar}
        onClose={() => setShowSettingsSidebar(false)}
      />
    </div>
  );
};

export default App;